$(document).ready(function () {
  document.querySelectorAll(".js-updown").forEach((el) => {
    el.classList.add("updown");
  });

  document.querySelectorAll(".js-rotating").forEach((el) => {
    el.classList.add("rotating");
  });

  const purchaseBtn = document.querySelector(".purchase__btn");
  const purchaseHideBlock = document.querySelector(".purchase__toggle-hided");

  $(purchaseBtn).click(function (e) {
    e.preventDefault();
    $(purchaseHideBlock).slideToggle("slow", function () {
      $(".js-text-toggle").toggleClass("purchase__text--unactive");
      $(".purchase__btn svg").toggleClass("arr-rotate");
    });
  });

  $(".hamburger").click(function () {
    $(this).toggleClass("is-active");
    if (this.getAttribute("data-active") == 0) {
      $(".header__menu").addClass("header__menu--active");
      $("html").css("overflow", "hidden");
      $("html").addClass("open");
      this.setAttribute("data-active", "1");
    } else {
      $(".header__menu").removeClass("header__menu--active");
      this.setAttribute("data-active", "0");
      $("html").css("overflow", "visible");
      $("html").removeClass("open");
    }
  });

  $(".btn-6")
    .on("mouseenter", function (e) {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find("span").css({
        top: relY,
        left: relX,
      });
    })
    .on("mouseout", function (e) {
      var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
      $(this).find("span").css({
        top: relY,
        left: relX,
      });
    });

  $(".popup-youtube").magnificPopup({
    type: "iframe",
  });

  var swiper = new Swiper(".swiper.omron__swiper", {
    effect: "fade",
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination.omron__pagination",
    },
  });

  const STYLE_SLIDE_QUANTITY = document.querySelector(".style__swiper-bar-all");
  const STYLE_SLIDE_COUNTER = document.querySelector(
    ".style__swiper-bar-active"
  );
  const STYLE_SLIDE_ACTIVE = document.querySelectorAll(".style__slide");
  const STYLE_SWIPER = document.querySelector(".style__swiper");

  var swiper = new Swiper(".swiper.style__swiper", {
    on: {
      init: function () {
        STYLE_SLIDE_QUANTITY.innerHTML = `${this.slides.length}`;
        STYLE_SLIDE_COUNTER.innerHTML = `${this.activeIndex + 1}`;
        STYLE_SWIPER.style.height = `${
          STYLE_SLIDE_ACTIVE[this.activeIndex].getBoundingClientRect().height
        }px`;
      },
      slideChange: function () {
        STYLE_SLIDE_COUNTER.innerHTML = `${this.activeIndex + 1}`;
        STYLE_SWIPER.removeAttribute("style");
        STYLE_SWIPER.style.height = `${
          STYLE_SLIDE_ACTIVE[this.activeIndex].getBoundingClientRect().height
        }px`;
      },
    },

    pagination: {
      el: ".swiper-pagination.style__swiper-pagination",
      type: "progressbar",
    },
    navigation: {
      nextEl: ".swiper-button-next.style__swiper-next",
      prevEl: ".swiper-button-prev.style__swiper-prev",
    },
    breakpoints: {
      0: {
        centeredSlides: true,
      },
      768: {
        spaceBetween: 0,
      },
      1366: {
        spaceBetween: 40,
      },
    },
  });

  var advantageSwiper = new Swiper(".swiper.advantage__swiper", {
    loop: true,
    pagination: {
      el: ".swiper-pagination.advantage__pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next.advantage__swiper-next",
      prevEl: ".swiper-button-prev.advantage__swiper-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1.1,
      },
      768: {
        slidesPerView: 1.2,
      },
    },
    speed: 1500,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    // on: {
    // 	reachEnd: function () {
    // 		this.params.autoplay.reverseDirection = true;
    // 	},
    // 	reachBeginning: function () {
    // 		this.params.autoplay.reverseDirection = false;
    // 	}
    // }
  });

  var swiper = new Swiper(".swiper.partners__swiper", {
    loop: true,
    centeredSlides: true,
    speed: 1000,
    spaceBetween: 32,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination.partners__pagination",
    },
    breakpoints: {
      0: {
        slidesPerView: 1.9,
      },
      768: {
        slidesPerView: 3,
      },
      1366: {
        slidesPerView: 5,
      },
    },
  });

  const mapData = {
    0: {
      object: "Сервисно-консультационный центр",
      address: "125413, Москва, Воротниковский пер., д. 7, стр. 3, комн. 14",
      geo: [55.8571040688793, 37.520877499999976],
      tel: ["8 800 555 00 80"],
      schedule: "c 1.00 до 11.00   пн - пт",
    },
    1: {
      object: "Сервисный центр",
      address: "127006, Москва, Воротниковский пер., д. 7, стр. 3",
      geo: [55.769838568983076, 37.600154],
      tel: ["+7 (495) 987-18-92", "+7 (495) 987-18-93"],
      schedule: "c 2.00 до 22.00   пн - пт",
    },
  };

  ymaps.ready(function () {
    var myMap = new ymaps.Map(
      "map",
      {
        center: [55.751574, 37.573856],
        zoom: 9,
      },
      {
        searchControlProvider: "yandex#search",
      }
    );
    for (var prop in mapData) {
      let pin = `pin${prop}`;

      pin = new ymaps.Placemark(
        mapData[prop].geo,
        {
          hintContent: mapData[prop].object,
        },
        {
          // Опции.
          // Необходимо указать данный тип макета.
          thisId: prop,
          iconLayout: "default#imageWithContent",
          // Своё изображение иконки метки.
          iconImageHref: "img/map-pin.svg",
          // Размеры метки.
          iconImageSize: [32, 46],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-16, -23],
          // Смещение слоя с содержимым относительно слоя с картинкой.
        }
      );

      myMap.geoObjects.add(pin);

      pin.events.add("click", function (e) {
        let clickedId = pin.options._options.thisId;
        const variation = document.querySelector(".map__variation");
        const address = document.querySelector(".map__address");
        const telsList = document.querySelector(".map__tels");
        const schedule = document.querySelector(".map__schedule");

        let tels = mapData[clickedId].tel
          .map((el) => {
            return `
						<li>
									<a href="tel:${el}">${el}</a>
						</li>
						`;
          })
          .join("");

        variation.innerHTML = mapData[clickedId].object;
        address.innerHTML = mapData[clickedId].address;
        schedule.innerHTML = mapData[clickedId].schedule;
        telsList.innerHTML = "";
        telsList.insertAdjacentHTML("beforeend", tels);
      });

      pin.events
        .add("mouseenter", function (e) {
          e.get("target").options.set("iconImageHref", "img/map-pin-hover.svg");
        })
        .add("mouseleave", function (e) {
          e.get("target").options.set("iconImageHref", "img/map-pin.svg");
        });
    }

    myMap.controls.remove("geolocationControl");
    myMap.controls.remove("searchControl");
    myMap.controls.remove("trafficControl");
    myMap.controls.remove("typeSelector");
    myMap.controls.remove("fullscreenControl");
    myMap.controls.remove("rulerControl");
    myMap.behaviors.disable(["scrollZoom"]);

    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function () {
        return (
          isMobile.Android() ||
          isMobile.BlackBerry() ||
          isMobile.iOS() ||
          isMobile.Opera() ||
          isMobile.Windows()
        );
      },
    };
    if (isMobile.any()) {
      myMap.behaviors.disable("drag");
    }
  });

  document.querySelectorAll(".js-anchor").forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      var elem = anchor.getAttribute("href");
      $("html,body").animate(
        {
          scrollTop: $(elem).offset().top,
        },
        "slow"
      );
      if (document.querySelector("html").classList.contains("open")) {
        $(".header__menu").removeClass("header__menu--active");
        $(".hamburger").attr("data-active", "0");
        $("html").css("overflow", "visible");
        $("html").removeClass("open");
        $(".hamburger").removeClass("is-active");
      }
    });
  });

  const animEvolvImg = document.querySelector(".evolv__omron");
  const animEvolvBgImg = document.querySelector(".evolv__image");
  const animEvolvTitle = document.querySelector(".evolv__title");
  const animEvolvTexts = document.querySelectorAll(".evolv__subtitle");
  gsap.registerPlugin(ScrollTrigger);

  if (animEvolvImg && window.matchMedia(`(min-width: 1366px)`).matches) {
    const trigger = (end = "top") => ({
      trigger: animEvolvImg,
      scrub: true,
      start: "-=200",
      end,
    });

    gsap.from(animEvolvImg, {
      scale: 1.3,
      // yPercent: -50,
      // xPercent: -50,
      top: 100,
      left: "40%",
      ease: "none",
      // transformOrigin: "50% 50%",
      scrollTrigger: trigger(),
    });

    if (animEvolvBgImg) {
      gsap.from(animEvolvBgImg, {
        scrollTrigger: trigger(),
        opacity: 0,
        yPercent: 20,
        ease: "none",
      });
    }

    const textAnim = {
      scrollTrigger: trigger("bottom 20%"),
      opacity: 0,
      yPercent: 100,
      ease: Power1.easeOut(),
    };

    if (animEvolvTitle) {
      gsap.from(animEvolvTitle, textAnim);
    }

    if (animEvolvTexts[0]) {
      Array.from(animEvolvTexts).forEach((text) => {
        gsap.from(text, textAnim);
      });
    }
  }

  const appBlock = document.querySelector("#app");
  const advantageBlock = document.querySelector(".advantage");

  if (
    appBlock &&
    advantageBlock &&
    window.matchMedia(`(min-width: 1366px)`).matches
  ) {
    gsap.to(appBlock, {
      yPercent: -100,
      ease: "none",
      stagger: 0.5,
      scrollTrigger: {
        trigger: "#anim-wrapper",
        start: "top top",
        end: "+=300%",
        scrub: true,
        pin: true,
      },
    });

    gsap.set(appBlock, { zIndex: 4 });

    const advantageTitle = advantageBlock.querySelector(".advantage__title");
    setAnchor(advantageTitle, "#app", "bottom-top");

    function setAnchor(elem, anchor, pos) {
      elem.setAttribute("data-aos-anchor", anchor);
      elem.setAttribute("data-aos-anchor-placement", pos);
    }
  }
});

const video = document.querySelector("video");

$(video).on("inview", function (event, isInView) {
  if (isInView) {
    video.play();
  } else {
    video.pause();
  }
});

const splittedTexts = document.querySelectorAll("[data-split]");
if (splittedTexts[0]) {
  for (let text of splittedTexts) {
    const lines = text.textContent.trim().split(/\r\n|\r|\n/g);
    const dur = text.dataset.duration || '';
    const delay = text.dataset.delay || '';
    const once = text.dataset.once || '';
    text.innerHTML = lines
      .map(
        (line, i) =>
          `<span class="line-wrap">
            <span
              data-aos="fade-up"
              ${once ? `data-aos-once="true"` : ``}
              ${dur ? `data-aos-duration="${dur}"` : ``}
              ${delay ? `data-aos-delay="${delay}"` : ``}
          >
                ${String(line)}
            </span>
          </span>`
      )
      .join("");
  }
}
